import React from "react";

export default ({ image, alt }) => {
    return (
        <div class="column is-3">
            <div class="card">
                <div class="card-image">
                    <figure class="image">
                    <img src={image} alt={alt} />
                    </figure>
                </div>
            </div>
        </div>
    )
};
