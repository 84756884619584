import * as React from 'react';
import { navigate } from 'gatsby-link';
import Page from "../layouts/Page";
import HeroContact from '../media/hero/hero-contact.svg';
import IllustrutionChat from '../media/illustration/Chat.png';
import LogoPartner from '../components/LogoPartner';
import AphpLogo from '../media/partners/aphp-logo.jpeg';
import ResilienceLogo from '../media/partners/resilience.png';
import CodocLogo from '../media/partners/codoc.png';
import HyperedgeLogo from '../media/partners/hyperedge.png';
import IpsenLogo from '../media/partners/ipsen.png';
import ResaamLogo from '../media/partners/resaam.png';
import TechHealLogo from '../media/partners/tech2heal.png';
import PixaidLogo from '../media/partners/pixaid.png';
import OrdreLogo from '../media/partners/ordre.png';
import UrgencesLogo from '../media/partners/urgenceschrono.png';
import WithingsLogo from '../media/partners/withings.png';

const HERO_IMAGE_WIDTH_PX = 400;

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default () => {
  const [state, setState] = React.useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  };

  return (<Page title="Contact Akimed" description="Une idée de projets dans la santé ? Data ou IA, on vous accompagne sur le terrain technique ou fonctionnel.">
    {/* <section className="hero first-hero is-primary">
      <div className="hero-body">
        <div className="container">
          <div class="columns is-vcentered">
            <div class="column">
              <h1 className="title">
              
              </h1>
            </div>
            <div class="column">
              <div style={{ float: 'right', marginRight: '0.75rem' }}>
                <div style={{width: `${HERO_IMAGE_WIDTH_PX - 1}px`, height: '313px', overflow: 'hidden' }}>
                  <img src={HeroContact} style={{ maxWidth: `${HERO_IMAGE_WIDTH_PX}px` }}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}

    <section className="section">
      <div className="container">
        <div class="columns">
          <div class="column is-5">
            <h1 class="title">Planifiez un rendez-vous avec l’équipe Akimed</h1>
            <figure class="image">
              <img src={IllustrutionChat} />
            </figure>
          </div>
          <div class="column is-6 is-offset-1">
            <form name="contact" class="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field"
              action="/contact-success"
              onSubmit={handleSubmit}>
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <label>
                Don’t fill this out: <input name="bot-field" onChange={handleChange} />
              </label>
            </p>

            <div class="field">
              <label class="label">Nom</label>
              <div class="control">
                <input class="input" type="text" placeholder="Nom" name="name" onChange={handleChange} />
              </div>
            </div>

            <div class="columns">
            <div class="column">
            <div class="field">
              <label class="label">Organisation</label>
              <div class="control">
                <input class="input" type="text" placeholder="Organisation" name="organization" onChange={handleChange} />
              </div>
            </div>
            </div>
            <div class="column">
            <div class="field">
              <label class="label">Rôle</label>
              <div class="control">
                <input class="input" type="text" placeholder="Chief Digital Officer" name="role" onChange={handleChange} />
              </div>
            </div>
            </div>
            </div>

            <div class="columns">
            <div class="column">
            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input class="input" type="email" placeholder="nom@organisation.com" name="email" onChange={handleChange} />
              </div>
            </div>
            </div>
            <div class="column">
            <div class="field">
              <label class="label">Téléphone</label>
              <div class="control">
                <input class="input" type="tel" placeholder="+33 x xx xx xx xx" name="phone" onChange={handleChange} />
              </div>
            </div>
            </div>
            </div>

            <div class="field">
              <label class="label">Question</label>
              <div class="control">
                <textarea class="textarea" placeholder="Comment pouvons-nous vous aider ?" name="question" rows="9" onChange={handleChange} ></textarea>
              </div>
            </div>

            <div class="field is-grouped">
              <div class="control">
                <button class="button is-danger"><strong>Envoyer</strong></button>
              </div>
            </div>
          </form>
          </div>
        </div>
        
      </div>
    </section>
    <section>
        <div className="container">
            <h1 className="title has-text-centered">Plus de 30 partenaires nous font confiance</h1>
            <div class="columns is-centered" style={{marginTop: "50px", marginBottom: "50px"}}>
              <div class="column is-7">
                <div class="columns is-centered is-mobile">
                    <LogoPartner image={ResilienceLogo} alt="Resilience" />
                    <LogoPartner image={IpsenLogo} alt="IPSEN" />
                    <LogoPartner image={AphpLogo} alt="APHP" />
                </div>
                <div class="columns is-centered is-mobile">
                    <LogoPartner image={CodocLogo} alt="Codoc" />
                    <LogoPartner image={HyperedgeLogo} alt="Hyperedge" />
                    <LogoPartner image={PixaidLogo} alt="Pixaid" />
                </div>
                <div class="columns is-centered is-mobile">
                    <LogoPartner image={WithingsLogo} alt="Withings" />
                    <LogoPartner image={ResaamLogo} alt="Resaam" />
                    <LogoPartner image={TechHealLogo} alt="Tech 2 Heal" />
                </div>
                <div class="columns is-centered is-mobile">
                    <LogoPartner image={OrdreLogo} alt="Ordre des infirmiers" />
                    <LogoPartner image={UrgencesLogo} alt="Urgences Chrono / Focus Santé" />
                </div>
              </div>
            </div>
      </div>
    </section>
  </Page>);
};
